<template>
  <select class="flix-form-control" @change.prevent="setFilter" :value="id">
    <option :value="false" disabled>- {{ $t('message.pleaseUseBookingForm') }} - </option>
    <optgroup :label="$tc('message.calendar', $store.getters.assistents.length)">
      <option v-for="assistent in $store.getters.assistents" :key="assistent.ID" :value="assistent.ID">
        {{ assistent.title }}
      </option>
    </optgroup>
    <optgroup v-if="all" :label="$t('message.allCalendar')">
      <option value="*"><slot name="allCalendar">{{ $t('message.allCalendar') }}</slot></option>
    </optgroup>
  </select>
</template>
<script>
export default {
  name: 'CalendarSelector',
  components: {},
  props: {
    all: {
      type: Boolean,
      default () { return true }
    },
    empty: {
      type: Boolean,
      default () { return false }
    },
    id: {
      type: [Boolean, Number, String]
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    setFilter (val) {
      this.$store.commit('setActiveAssistent', this.$store.getters.assistentsByID[val.target.value])
      this.$emit('save', val.target.value)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
